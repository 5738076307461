/**
 * Events:
 *  Player Load
 *  PIP Enabled
 *  PIP Disabled
 */
import { gaSendEvent } from '@/client/js/analytics/googleAnalytics';
import { gaSendStandardEvent } from '@/platform/jw-player/client/js/analytics/gaActions';
import { getPermalinkConfig } from '@/platform/jw-player/client/js/analytics/utils';
import { playerState } from '@/platform/jw-player/client/js/analytics/state/playerState';
/**
 * Integrate analytics on JW Player Events.
 * NOTE: getConfig() and getFloating are built-in JW Player methods.
 *
 * @param player The JW Player instance.
 */
export default function playerEvent(player) {
    // Early return if player does not exist.
    if (!player) {
        return;
    }
    var playerConfig = player.getConfig();
    // Send GA PLAYER LOAD event with assigned Category and Action.
    gaSendEvent('Player Load', playerConfig.pid || '', getPermalinkConfig());
    /**
     * When clicking to play, the video plays in fullscreen if all conditions are met:
     *
     *  — The player is inline.
     *  — The player is vertical (taller than it is wide).
     *  — The player is not currently in fullscreen.
     *  — The player does not autostart.
     *  — Triggers only once per pageview.
     */
    var playerStateObject = playerState.get();
    var clickToPlayFullscreen = function () {
        var isSingleArticle = document.body.classList.contains('single-article');
        var isVerticalPlayer = player.getHeight() > player.getWidth();
        if (isSingleArticle
            && isVerticalPlayer
            && !player.getFullscreen()
            && !player.getConfig().autostart
            && !playerStateObject.hasClickedToPlayFullscreen) {
            player.setFullscreen(true);
            playerState.update({ hasClickedToPlayFullscreen: true });
        }
    };
    // This should only happen once per pageview.
    if (!playerStateObject.hasClickedToPlayFullscreen) {
        player.once('play', clickToPlayFullscreen);
        player.once('adPlay', clickToPlayFullscreen);
    }
    // Send the floating player events.
    // Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronfloat
    player.on('float', function () {
        if (player.getFloating()) {
            gaSendStandardEvent('PIP Enabled');
        }
        else {
            gaSendStandardEvent('PIP Disabled');
        }
    });
}
